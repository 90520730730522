import { t } from "i18next";

export const IMPORT_TYPE = {
  FIRST_TIME_IMPORT: "FIRST_TIME_IMPORT",
  RETURN: "RETURN",
  RE_IMPORT_WARRANTY: "RE_IMPORT_WARRANTY",
  TRANSFER_SERIAL: "TRANSFER_SERIAL",
  OTHER: "OTHER",
};

export const WARRANTY_TYPE = {
  INTERNAL: "INTERNAL",
  MANUFACTURE: "MANUFACTURE",
  CUSTOM: "CUSTOM",
  TICKET: "TICKET",
};

export const WARRANTY_ON_SALE_UNIT = {
  YEAR: "YEAR",
  MONTH: "MONTH",
  DAY: "DAY",
  UNSPECIFIELD: "UNSPECIFIELD",
};

export const warrantyTypeLabel = {
  [`${WARRANTY_TYPE.CUSTOM}`]: t("warranty.warrantyDetail.custom"),
  [`${WARRANTY_TYPE.INTERNAL}`]: t("warranty.warrantyDetail.internal"),
  [`${WARRANTY_TYPE.MANUFACTURE}`]: t("warranty.warrantyDetail.manufacture"),
  [`${WARRANTY_TYPE.TICKET}`]: t("warranty.warrantyDetail.ticket"),
};

export const warrantyTypeOptions = [
  {
    label: t("warranty.warrantyDetail.custom"),
    value: WARRANTY_TYPE.CUSTOM,
  },
  {
    label: t("warranty.warrantyDetail.internal"),
    value: WARRANTY_TYPE.INTERNAL,
  },
  {
    label: t("warranty.warrantyDetail.manufacture"),
    value: WARRANTY_TYPE.MANUFACTURE,
  },
  {
    label: t("warranty.warrantyDetail.ticket"),
    value: WARRANTY_TYPE.TICKET,
  },
];

export const onSaleUnitOptions = [
  {
    label: t("common.year"),
    value: WARRANTY_ON_SALE_UNIT.YEAR,
  },
  {
    label: t("common.monthB"),
    value: WARRANTY_ON_SALE_UNIT.MONTH,
  },
  {
    label: t("common.day"),
    value: WARRANTY_ON_SALE_UNIT.DAY,
  },
];

export const warrantyUnitLabel = {
  [WARRANTY_ON_SALE_UNIT.YEAR]: t("common.YEAR"),
  [WARRANTY_ON_SALE_UNIT.MONTH]: t("common.month"),
  [WARRANTY_ON_SALE_UNIT.DAY]: t("common.DAY"),
};

export const convertTotalNumber = (number) => {
  return number ? number : 0;
};

export const SORT_TYPES = {
  ascend: "ASC",
  descend: "DESC",
};

export const VALUE_TAGS_IMPORT_NOTE_TYPE = [
  {
    label: t("serialIE.FIRST_TIME_IMPORT"),
    value: IMPORT_TYPE.FIRST_TIME_IMPORT,
  },
  {
    label: t("serialIE.RETURN"),
    value: IMPORT_TYPE.RETURN,
  },
  {
    label: t("serialIE.RE_IMPORT_WARRANTY"),
    value: IMPORT_TYPE.RE_IMPORT_WARRANTY,
  },
  {
    label: t("serialIE.transfer_serial"),
    value: IMPORT_TYPE.TRANSFER_SERIAL,
  },
  {
    label: t("serialIE.other"),
    value: IMPORT_TYPE.OTHER,
  },
];
export const typeImport = {
  [`${IMPORT_TYPE.FIRST_TIME_IMPORT}`]: t("serialIE.FIRST_TIME_IMPORT"),
  [`${IMPORT_TYPE.RETURN}`]: t("serialIE.RETURN"),
  [`${IMPORT_TYPE.RE_IMPORT_WARRANTY}`]: t("serialIE.RE_IMPORT_WARRANTY"),
  [`${IMPORT_TYPE.TRANSFER_SERIAL}`]: t("serialIE.transfer_serial"),
  [`${IMPORT_TYPE.OTHER}`]: t("serialIE.other"),
};

export const EXPORT_TYPE = {
  ORDER: "ORDER",
  WARRANTY: "WARRANTY",
  RETURN_SUPPLIER: "RETURN_SUPPLIER",
  TRANSFER_SERIAL: "TRANSFER_SERIAL",
  OTHER: "OTHER",
};
export const optionExportRadio = [
  { label: t("serialIE.byOrder"), value: EXPORT_TYPE.ORDER },
  { label: t("serialIE.byWarranty"), value: EXPORT_TYPE.WARRANTY },
  { label: t("serialIE.by_return_supplier"), value: EXPORT_TYPE.RETURN_SUPPLIER },
  { label: t("serialIE.transfer_serial"), value: EXPORT_TYPE.TRANSFER_SERIAL },
  { label: t("serialIE.other"), value: EXPORT_TYPE.OTHER },
];

export const typeExport = {
  [`${EXPORT_TYPE.ORDER}`]: t("serialIE.byOrder"),
  [`${EXPORT_TYPE.WARRANTY}`]: t("serialIE.byWarranty"),
  [`${EXPORT_TYPE.RETURN_SUPPLIER}`]: t("serialIE.by_return_supplier"),
  [`${EXPORT_TYPE.TRANSFER_SERIAL}`]: t("serialIE.transfer_serial"),
  [`${EXPORT_TYPE.OTHER}`]: t("serialIE.other"),
};

export const VALUE_EXPORT_NOTE_TYPE = [
  {
    label: t("serialIE.byOrder"),
    value: EXPORT_TYPE.ORDER,
  },
  {
    label: t("serialIE.byWarranty"),
    value: EXPORT_TYPE.WARRANTY,
  },
  {
    label: t("serialIE.by_return_supplier"),
    value: EXPORT_TYPE.RETURN_SUPPLIER,
  },
  {
    label: t("serialIE.transfer_serial"),
    value: EXPORT_TYPE.TRANSFER_SERIAL,
  },
  {
    label: t("serialIE.other"),
    value: EXPORT_TYPE.OTHER,
  },
];
export const EXPORT_NOTES_TYPE = {
  SERIAL_EXPORT_NOTE: "SERIAL_EXPORT_NOTE",
  SERIAL_IMPORT_NOTE: "SERIAL_IMPORT_NOTE",
};

export const FILETYPE = {
  PDF: "PDF",
  JPEG: "JPEG",
  XLSX: "XLSX",
};

export const importTypeSerialConverter = (value) => {
  switch (value) {
    case IMPORT_TYPE.FIRST_TIME_IMPORT:
      return {
        status: "success",
        text: t("serialIE.FIRST_TIME_IMPORT"),
      };
    case IMPORT_TYPE.RETURN:
      return {
        status: "processing",
        text: t("serialIE.RETURN"),
      };
    case IMPORT_TYPE.RE_IMPORT_WARRANTY:
      return {
        status: "warning",
        text: t("serialIE.RE_IMPORT_WARRANTY"),
      };
    case IMPORT_TYPE.TRANSFER_SERIAL:
      return {
        status: "error",
        text: t("serialIE.transfer_serial"),
      };
    case IMPORT_TYPE.OTHER:
      return {
        status: "grey",
        text: t("serialIE.other"),
      };
    case EXPORT_TYPE.ORDER:
      return {
        status: "success",
        text: t("serialIE.byOrder"),
      };
    case EXPORT_TYPE.WARRANTY:
      return {
        status: "warning",
        text: t("serialIE.byWarranty"),
      };
    case EXPORT_TYPE.RETURN_SUPPLIER:
      return {
        status: "processing",
        text: t("serialIE.byReturnSublier"),
      };
    default:
      break;
  }
};
